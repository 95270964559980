<template>
    <video :id="id" preload="none" playsinline autoplay muted ref="video" class="w-100"></video>
</template>

<script>
import { io } from "socket.io-client";

const config = {
    iceServers: [
        {
            "urls": "stun:stun.l.google.com:19302",
        },
        {
            "urls": "turn:xturn1.glaze.live",
            "username": "xturnuser1",
            "credential": "xturnpw1"
        },
        {
            "urls": "turn:xturn2.glaze.live",
            "username": "xturnuser1",
            "credential": "xturnpw1"
        }
    ]
};

function updateBandwidthRestriction(sdp, bandwidth) {
  let modifier = 'AS';
  if (sdp.indexOf('b=' + modifier + ':') === -1) {
    // insert b= after c= line.
    sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
  } else {
    sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
  }
  return sdp;
}

export default {
    props: {
        id: String,
        play: Boolean,
        device: Object
    },
    data() {
        return {
            socket: null,
            peerConnection: null,
        };
    },
    watch: {
        device(item) {
            this.socket = io(item.host, {
                extraHeaders: {
                    "did": item.id
                }
            });

            this.socket.on("offer", (id, description) => {
                this.peerConnection = new RTCPeerConnection(config);
                this.peerConnection
                    .setRemoteDescription(description)
                    .then(() => this.peerConnection.createAnswer())
                    .then(sdp => this.peerConnection.setLocalDescription(sdp))
                    .then(() => {
                        this.peerConnection.localDescription.sdp = updateBandwidthRestriction(this.peerConnection.localDescription.sdp, 100);
                        this.socket.emit("answer", id, this.peerConnection.localDescription);
                    });
                this.peerConnection.ontrack = event => {
                    this.$refs.video.srcObject = event.streams[0];
                };
                this.peerConnection.onicecandidate = event => {
                    if (event.candidate) {
                        this.socket.emit("candidate", id, event.candidate);
                    }
                };
            });

            this.socket.on("candidate", (id, candidate) => {
                this.peerConnection
                    .addIceCandidate(new RTCIceCandidate(candidate))
                    .catch(e => console.error(e));
            });

            this.socket.on("connect", () => {
                this.socket.emit("watcher");
            });

            this.socket.on("broadcaster", () => {
                this.socket.emit("watcher");
            });
        },
        play(flag) {
            if (flag) {
                this.socket.connect();
            }
            else {
                this.closeSockets();
            }
        }
    },
    methods: {
        closeSockets() {
            if (this.socket) {
                this.socket.close();
            }
            if (this.peerConnection) {
                this.peerConnection.close();
            }
        }
    },
};
</script>