import { bannerApi } from "@/api";

const BannerNamespace = "banner";

const BannerMutaions = {
  MUTATION_UPDATE_BANNER_LIST: "MUTATION_UPDATE_BANNER_LIST" // BANNER 리스트 업데이트
};

const BannerActions = {
  ACTION_READ_BANNER_LIST: "ACTION_READ_BANNER_LIST" // BANNER 리스트 조회 요청
};

const createInitialState = () => ({
  banner_list: []
});

const getters = {
  getBannerList(state) {
    return state.banner_list;
  }
};

const mutations = {
  //Banner 리스트 업데이트
  [BannerMutaions.MUTATION_UPDATE_BANNER_LIST](state, res) {
    state.banner_list = res.list;
  }
};

const actions = {
  //Banner 리스트 조회 요청
  async [BannerActions.ACTION_READ_BANNER_LIST](context) {
    const { commit } = context;

    let result = await bannerApi.requestBannerList().then(res => {
      commit("MUTATION_UPDATE_BANNER_LIST", res.data);

      return res;
    });

    return result;
  }
};

export { BannerNamespace, BannerMutaions, BannerActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
