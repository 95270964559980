<!-- GameThemeSection.vue -->
<template>
  <section class="slot-theme xs-section">
    <div class="grid-container">
      <div class="all-games">
        <input
          type="radio"
          id="all-game"
          v-model="selectedTheme"
          name="game-theme"
          value="all"
          checked
          @change="updateSelectedTheme"
        />
        <label class="all-theme" for="all-game">
          <v-icon></v-icon>
          <span>All</span>
        </label>
      </div>
      <swiper class="swiper jackpot-swiper" :options="themeSwiperOption">
        <swiper-slide
          class="slot-slide swiper-slide"
          v-for="(list, index) in filteredGameThemes"
          :key="list.id"
        >
          <input
            type="radio"
            v-model="selectedTheme"
            :id="'game-' + index"
            name="game-theme"
            :value="list.id"
            @change="updateSelectedTheme"
          />
          <label class="game-theme" :for="'game-' + index">
            <img :src="list.imagePath" :alt="list.name" />
          </label>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { mapActions, mapGetters } from "vuex";
import { GameNamespace, GameActions } from "@/store/game";

export default {
  name: "GameThemeSection",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    defaultTheme: {
      type: String,
      default: "all"
    },
    selectedJackpotId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedTheme: this.defaultTheme,
      themeSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 5,
        observer: true,
        observeParents: true,
        grapCursor: true
      }
    };
  },
  watch: {
    selectedJackpotId(newVal, oldVal) {
      // selectedJackpotId가 null이 되거나 다른 잭팟이 선택될 때 selectedTheme을 all으로 설정
      if (newVal === null || newVal !== oldVal) {
        this.selectedTheme = "all";
        this.updateSelectedTheme(); // 초기화된 값 상위에 전달
      }
    }
  },
  computed: {
    ...mapGetters(GameNamespace, ["getGameList"]),
    gameList() {
      return this.getGameList || []; // 게임 리스트가 없을 때 빈 배열 반환
    },
    filteredGameThemes() {
      // selectedJackpotId가 null일 경우 모든 gameTheme 반환, 그렇지 않으면 jackpotId에 맞춰 필터링
      const filteredThemes =
        this.selectedJackpotId !== null
          ? this.getGameList.filter(
              theme => theme.jackpotId === this.selectedJackpotId
            )
          : this.gameList;
      this.updateFilteredGameIds(filteredThemes.map(theme => theme.id));
      return filteredThemes;
    }
  },
  mounted() {
    this.ACTION_READ_GAME_LIST();
  },
  methods: {
    ...mapActions(GameNamespace, {
      ACTION_READ_GAME_LIST: GameActions.ACTION_READ_GAME_LIST
    }),
    updateSelectedTheme() {
      this.$emit("update:selectedTheme", this.selectedTheme);
    },
    updateFilteredGameIds(gameIds) {
      // 필터링된 gameId 목록을 상위 컴포넌트에 전달
      this.$emit("update:allowedGameIds", gameIds);
    }
  }
};
</script>
