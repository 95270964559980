import axios from "./axios";

const host_info = process.env.VUE_APP_API_URL;

/**
 * [ XAPI ] 머신 - Machine Device 리스트 조회
 * @param {*} machine_id
 * @param {*} external_token
 * @returns
 */
export const requestMachineDeviceList = async (machineId, external_token) => {
  let response = null;

  try {
    response = await axios.get(`${host_info}/machines/${machineId}/devices`, {
      headers: {
        Authorization: `Bearer ${external_token}`
      }
    });
  } catch (error) {
    throw new Error(error);
  }
  return response;
};