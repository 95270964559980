<!-- BannerSection.vue -->
<template>
  <section class="slide-slot xs-section">
    <swiper class="swiper slot-swiper" :options="swiperOption">
      <template v-if="bannerList.length > 0">
        <swiper-slide
          class="slot-slide swiper-slide"
          v-for="(list, index) in bannerList"
          :key="index"
        >
          <a class="slide-link" :href="list.LinkUrl">
            <img
              :src="list.ImagePath"
              :alt="list.Name"
              @error="onImageError($event)"
            />
          </a>
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide class="slot-slide swiper-slide">
          <a class="slide-link" href="#"
            ><img :src="defaultBannerImage" alt="Default Banner"
          /></a>
        </swiper-slide>
      </template>

      <template v-slot:pagination>
        <div class="swiper-pagination"></div>
      </template>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapActions, mapGetters } from "vuex";
import { BannerNamespace, BannerActions } from "@/store/banner";

export default {
  name: "BannerSection",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      defaultBannerImage: require("@assets/img/banner/banner-example.png") // 기본 이미지 경로 설정
    };
  },
  computed: {
    ...mapGetters(BannerNamespace, ["getBannerList"]),
    bannerList() {
      return this.getBannerList || []; // 배너 리스트가 없을 때 빈 배열 반환
    }
  },
  mounted() {
    this.ACTION_READ_BANNER_LIST();
  },
  methods: {
    ...mapActions(BannerNamespace, {
      ACTION_READ_BANNER_LIST: BannerActions.ACTION_READ_BANNER_LIST
    }),
    onImageError(event) {
      event.target.src = this.defaultBannerImage; // 이미지 로드 실패 시 기본 이미지 설정
    }
  }
};
</script>
