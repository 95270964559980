<template>
  <div class="xs-container mystery-jackpot">
    <!--BMM 인증을 위한 임시 숨김 처리 ( mystery-jackpot)-->
    <!-- <section class="xs-section jackpot-section">
      <div class="jackpot-title">
        <div class="jackpot_title-inner">
          <img src="@assets/img/jackpot/mystery_jackpot.gif" alt="" />
        </div>
      </div>
      <div class="jackpot-inner">
        <ul class="jackpot-lists">
          <li class="jackpot-list first">
            <p class="jackpot-num"></p>
          </li>
          <li class="jackpot-list">
            <p class="jackpot-num"></p>
          </li>
          <li class="jackpot-list">
            <p class="jackpot-num"></p>
          </li>
        </ul>
        <ul class="jackpot-lists second">
          <li class="jackpot-list">
            <p class="jackpot-num"></p>
          </li>
          <li class="jackpot-list">
            <p class="jackpot-num"></p>
          </li>
          <li class="jackpot-list">
            <p class="jackpot-num"></p>
          </li>
        </ul>
      </div>
    </section>
    <section class="xs-section">
      <ul class="game-type">
        <li class="type-list">
          <img class="type-img" src="@assets/img/category_jackpot00.png" />
        </li>
        <li class="type-list">
          <img class="type-img" src="@assets/img/category_jackpot01.png" />
        </li>
        <li class="type-list">
          <img class="type-img" src="@assets/img/category_jackpot02.png" />
        </li>
        <li class="type-list">
          <button class="type-btn">
            <img src="@assets/img/jackpot/gametypes_btn.png" alt="" />
          </button>
        </li>
      </ul>
    </section> -->

    <div id="game_filter">
      <img
        class="swiper-button-prev swiper-btn-prev mt-0"
        slot="button-prev"
        :style="{
          width: '1.3125rem',
          height: '2.625rem',
          color: '#353854',
          top: 'auto',
          left: '0px',
          position: 'relative'
        }"
        :src="require(`@assets/img/arrow/vector_left.png`)"
      />
      <Swiper
        class="swiper"
        ref="mySwiper"
        :options="swiperOption"
        @transitionEnd="transitionEnd"
      >
        <SwiperSlide
          class="slide"
          v-for="(item, index) in machineGameTypeList"
          :key="index"
        >
          <img :src="require(`@assets/img/banner/${item.image}`)" />
        </SwiperSlide>
      </Swiper>

      <img
        class="swiper-button-next swiper-btn-next mt-0"
        slot="button-next"
        :style="{
          width: '1.3125rem',
          height: '2.625rem',
          color: '#353854',
          top: 'auto',
          left: '0px',
          right: '0px',
          position: 'relative'
        }"
        :src="require(`@assets/img/arrow/vector_right.png`)"
      />
      <div class="backdrop-effect"></div>
    </div>

    <v-card
      class="mx-auto filter-area"
      elevation="0"
      :style="{
        background:
          'linear-gradient(270deg, rgba(59, 62, 96, 0.50) 0%, rgba(30, 33, 52, 0.50) 49.92%, rgba(59, 62, 96, 0.50) 100%)'
      }"
    >
      <div class="d-flex filter-box">
        <div class="game-filter">
          <v-btn-toggle v-model="isState" mandatory>
            <v-btn class="filter-btn"
              ><v-icon class="all"></v-icon
              >{{ $t("allGames.gameStatus.all") }}</v-btn
            >
            <v-btn class="filter-btn"
              ><v-icon class="available"></v-icon
              >{{ $t("allGames.gameStatus.available") }}</v-btn
            >
            <v-btn class="filter-btn"
              ><v-icon class="unavailable"></v-icon
              >{{ $t("allGames.gameStatus.unavailable") }}</v-btn
            >
          </v-btn-toggle>
        </div>
        <div class="game-etc">
          <v-btn
            class="favorite-filter"
            elevation="0"
            :disabled="disableFavorite"
            :class="this.isFavorite ? 'active' : ''"
            @click="handleFavorite()"
          >
            <v-icon></v-icon>
            {{ $t("allGames.favorites") }}</v-btn
          >
          <div class="game-total">
            <v-icon></v-icon>
            <p>{{ filterList.length }}</p>
          </div>
        </div>
      </div>
    </v-card>

    <section class="slot-allgame xs-section">
      <div class="allgame-inner">
        <div class="allgame-box">
          <div class="main-games">
            <ul class="main-game-lists">
              <li
                class="main-game-list"
                v-for="(item, index) in filterList"
                :key="index"
              >
                <div
                  class="main-game-box"
                  :class="item.state === 2 ? 'use' : ''"
                >
                  <div class="main-game-img hot" @click="handleGame(item)">
                    <div class="click-img">
                      <div class="game-img">
                        <img
                          :src="require(`@assets/img/main/${item.url}`)"
                          :alt="item.title"
                        />
                      </div>
                      <div class="game-img" v-if="item.url02 !== null">
                        <img
                          :src="require(`@assets/img/main/${item.url02}`)"
                          :alt="item.title"
                        />
                      </div>
                    </div>
                    <div class="game-state">
                      <p
                        class="state"
                        :class="[
                          {
                            disabled: item.state == 0,
                            idle: item.state == 2,
                            disuse:
                              item.state == 1 ||
                              item.state === 3 ||
                              item.state === 4
                          }
                        ]"
                      >
                        {{ machineState(item.state) }}
                      </p>
                    </div>
                    <div class="machine-num">
                      <p class="machine-id"># {{ item.machine_number }}</p>
                    </div>
                    <div class="state-filter">
                      <div class="use-state watch" v-if="item.state == 2">
                        <div class="machine-logo">
                          <img
                            :src="
                              require(`@assets/img/main/${item.machine_url}`)
                            "
                          />
                        </div>
                        <v-btn class="watch-btn play">
                          <v-icon></v-icon>
                          <span>{{ $t("machineStatus.playNow") }}</span>
                        </v-btn>
                      </div>
                      <div class="use-state watch" v-else-if="item.state == 3">
                        <div class="machine-logo">
                          <img
                            :src="
                              require(`@assets/img/main/${item.machine_url}`)
                            "
                          />
                        </div>
                        <v-btn class="watch-btn">
                          <v-icon></v-icon>
                          <span>{{ $t("machineStatus.watching") }}</span>
                        </v-btn>
                      </div>
                      <div class="use-state" v-else-if="item.state == 4">
                        <img src="@assets/img/main/locked-icon.png" alt="" />
                        <p class="state">{{ $t("machineStatus.locked") }}</p>
                      </div>
                      <div class="use-state" v-else>
                        <img src="@assets/img/state-icon.png" alt="" />
                        <p class="state">
                          {{ $t("machineStatus.maintenance") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="main-game-title hot">
                    <p>{{ item.title }}</p>
                    <div class="like-icon" @click="handleLike(index)">
                      <i
                        id="heart"
                        class="heart-icon"
                        :class="item.isLike ? 'active' : ''"
                      ></i>
                    </div>
                  </div>
                  <!-- BMM 인증 이후 삭제 결정 -->
                  <!-- <div class="main-game-img hot">
                  <div class="game-exp">
                    <p class="score">
                      <span>{{ item.number }}</span>
                    </p>
                    <p class="game-caption">{{ item.subTitle }}</p>
                    <div class="like-icon" @click="handleLike(index)">
                      <i
                        id="heart"
                        class="heart-icon"
                        :class="item.isLike ? 'active' : ''"
                      ></i>
                    </div>
                  </div>
                  <div class="click-img" @click="handleGame(index)">
                    <img
                      :src="require(`@assets/img/${item.url}`)"
                      alt="hotgame1"
                    />
                    <img
                      :src="require(`@assets/img/${item.url}`)"
                      alt="hotgame1"
                    />
                    <div class="game-state">
                      <p
                        class="state"
                        :class="[
                          {
                            ready: item.state == 1,
                            using: item.state == 2,
                            disable: item.state == 3
                          }
                        ]"
                      >
                        {{ machineState(item.state) }}
                      </p>
                    </div>
                    <div class="state-filter">
                      <img
                        v-if="item.state == 1"
                        src="@assets/img/play_btn.png"
                        alt=""
                      />
                      <div class="use-state" v-else-if="item.state == 2">
                        <img src="@assets/img/idle_icon02.png" alt="" />
                        <p class="state">사용중...</p>
                      </div>
                      <div class="use-state" v-else>
                        <img src="@assets/img/idle_icon.png" alt="" />
                        <p class="state">사용불가</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="main-game-title hot">
                  <p>{{ item.title }}</p>
                  <div class="disable-icon" @click="handleShow(index)">
                    <i
                      class="eye-icon"
                      :class="item.isShow ? 'active' : ''"
                    ></i>
                  </div>
                </div> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div>
      <v-dialog
        id="signin_modal"
        v-if="signInDialog"
        v-model="signInDialog"
        activator="parent"
        width="auto"
      >
        <SignInDialog @closeDialog="closeSignInDialog" />
      </v-dialog>
      <v-dialog
        content-class="alert"
        v-if="notifyDialog"
        v-model="notifyDialog"
        activator="parent"
        width="auto"
      >
        <AlertDialog
          @closeDialog="closeNotifyDialog"
          :message="notifyMessage"
          :isConfirm="false"
        />
      </v-dialog>

      <PlayViewDialog
        :dialog.sync="playViewDialog"
        :machine="viewTargetMachine"
        @close="closePlayViewDialog"
        @handleGame="handleViewTargetMachineGame"
      ></PlayViewDialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AuthNamespace } from "@/store/auth";
import { MachineNamespace, MachineActions } from "@/store/machine";

import SignInDialog from "@views/components/common/dialog/SignInDialog.vue";
import AlertDialog from "@views/components/common/dialog/AlertDialog.vue";
import PlayViewDialog from "@views/components/common/dialog/PlayViewDialog.vue";

import { HubConnectionState } from "@microsoft/signalr";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

const MACHINE_FILTER = {
  ALL: 0,
  AVAILABLE: 1,
  UNAVAILABLE: 2
};

const MACHINE_STATE = {
  DISABLED: 0,
  PREPARING: 1,
  IDLE: 2,
  USING: 3,
  Locked: 4
};

export default {
  components: {
    SignInDialog,
    AlertDialog,
    PlayViewDialog,
    Swiper,
    SwiperSlide
  },
  props: ["gameId"],
  data() {
    return {
      // 머신 카테고리 리스트
      machineGameTypeList: [
        { gameId: 0, title: "all", image: "banner00.png" },
        { gameId: 1, title: "DOUBLE MONEY LINK", image: "banner01.png" },
        { gameId: 2, title: "Duo Fu Duo Cai", image: "banner02.png" },
        { gameId: 3, title: "5 Dragons Ultra", image: "banner03.png" },
        { gameId: 4, title: "GLAZE", image: "banner04.png" },
        { gameId: 5, title: "Huff more Puff", image: "banner05.png" },
        { gameId: 6, title: "Coin Combo", image: "banner06.png" }
      ],
      signInDialog: false,
      notifyDialog: false,
      playViewDialog: false,
      notifyMessage: null,
      viewTargetMachine: {},
      swiper: null,
      swiperOption: {
        slidesPerView: "auto",
        direction: "horizontal",
        effect: "coverflow", // 효과
        grabCursor: true, // (true일 때) 커서로 잡아 슬라이드
        observer: true, // 스와이퍼 업데이트
        observeParents: true, // 스와이퍼 부모요소의 변화에따라 업데이트
        centeredSlides: true, // 슬라이드 가운데 정렬
        centeredSlidesBounds: true,
        loop: true, //무한 반복
        speed: 500, // 속도 조절
        coverflowEffect: {
          rotate: 0, //슬라이더 회전 각
          stretch: 50, // 슬라이더간 거리(픽셀) : 클수록 슬라이더가 서로 많이 겹침
          depth: 300, // 깊이 효과값 : 클수록 멀리있는 느낌이 강해짐
          modifier: 1, // 효과 배수 : 위 숫자값들에 이 값을 곱하기 처리하여 효과를 강하게 처리함
          slideShadows: true // 슬라이더 그림자 : 3D 효과를 강조하기 위한 회전시 흐릿한 효과
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          768: {
            coverflowEffect: {
              stretch: 100
            }
          }
        },
        on: {
          slideChange() {
            let keyframes = [
              { transform: "translate(-50%, -50%) scale(1)" },
              { transform: "translate(-50%, -50%) scale(0.95)" },
              { transform: " translate(-50%, -50%) scale(1)" }
            ];
            let options = {
              duration: 1300,
              easing: "ease-in-out"
            };
            document
              .querySelector(".backdrop-effect")
              .animate(keyframes, options);
          }
        }
      },
      selectMachine: null,
      isState: 0, // 필터 박스 - [ 0: all, 1: Available, 2: Unavailable]
      isFavorite: false, // 필터 박스 즐겨찾기 토글 상태
      currentSwiperIndex: 0,
      isActive: false
    };
  },
  computed: {
    ...mapGetters(AuthNamespace, [
      "getExternalToken",
      "getPlayerId",
      "getLoginCheck"
    ]),

    ...mapGetters(MachineNamespace, [
      "getMachineList",
      "getMachineId",
      "getMachineConnectionState"
    ]),

    // 필터링된 게임 머신 리스트 반환 이벤트
    filterList() {
      let machineFilterList = [];

      switch (this.isState) {
        // All
        case MACHINE_FILTER.ALL: {
          machineFilterList = this.getMachineList;
          break;
        }

        case MACHINE_FILTER.AVAILABLE: {
          machineFilterList = this.getMachineList.filter(m => m.state === 2);
          break;
        }

        case MACHINE_FILTER.UNAVAILABLE: {
          machineFilterList = this.getMachineList.filter(m => m.state !== 2);
          break;
        }
      }

      // 즐겨찾기 항목 검사
      if (this.isFavorite) {
        machineFilterList = machineFilterList.filter(m => m.isLike === true);
      }

      return machineFilterList;
    },

    machineState() {
      return state => {
        let result = "";
        switch (state) {
          case MACHINE_STATE.IDLE:
            // 사용가능
            result = this.$t("machineStatus.idle");
            break;
          case MACHINE_STATE.USING:
            // 사용중
            result = this.$t("machineStatus.occupied");
            break;
          case MACHINE_STATE.DISABLED:
            // 사용불가
            result = this.$t("machineStatus.maintenance");
            break;
          case MACHINE_STATE.PREPARING:
            // 사용불가
            result = this.$t("machineStatus.preparing");
            break;
          case MACHINE_STATE.Locked:
            // 잠김
            result = this.$t("machineStatus.locked");
            break;
          default:
            break;
        }
        return result;
      };
    },

    // 즐겨찾기 버튼 비활성화 검사
    disableFavorite() {
      const favoriteMachine = this.filterList.filter(m => m.isLike === true);
      if (favoriteMachine.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  mounted() {
    // Swiper 카테고리 초기화
    if (this.gameId != undefined) {
      this.$refs.mySwiper.$swiper.slideTo(this.gameId);
      this.selectMachine = this.machineGameTypeList[this.gameId];
    } else {
      this.selectMachine = this.machineGameTypeList[0];
    }

    this.requestMachineList(this.selectMachine.gameId);
  },

  methods: {
    ...mapActions(MachineNamespace, {
      ACTION_READ_MACHINE_LIST: MachineActions.ACTION_READ_MACHINE_LIST,
      ACTION_CREATE_MACHINE_PLAYER: MachineActions.ACTION_CREATE_MACHINE_PLAYER,
      ACTION_DELETE_MACHINE_PLAYER: MachineActions.ACTION_DELETE_MACHINE_PLAYER,
      ACTION_READ_MACHINE_GAME_TYPE:
        MachineActions.ACTION_READ_MACHINE_GAME_TYPE
    }),

    handleLike(index) {
      this.getMachineList[index].isLike = !this.getMachineList[index].isLike;
    },
    handleShow(index) {
      this.getMachineList[index].isShow = !this.getMachineList[index].isShow;
    },
    handleGame(item) {
      // 로그인 상태
      if (this.getLoginCheck) {
        if (this.viewTargetMachine != item && item.state === 3) {
          this.viewTargetMachine = item;
          this.ACTION_READ_MACHINE_GAME_TYPE(item.machine_id);
          this.openPlayViewDialog();
        } else {
          const machine_id = item.machine_id;

          // 선택한 머신에 사용자 정보 등록
          this.createMachinePlayer(machine_id);
        }
      } else {
        this.openSignInDialog();
      }
    },
    async handleViewTargetMachineGame() {
      this.handleGame(this.viewTargetMachine);
    },
    handleFavorite() {
      this.isFavorite = !this.isFavorite;
    },

    // 머신 리스트 조회 요청
    async requestMachineList(gameId) {
      await this.ACTION_READ_MACHINE_LIST({
        bFilter: false,
        gameId: gameId
      }).then(() => {
        // 머신 리스트가 존재하는 경우
        if (this.getMachineList.length > 0) {
          // 머신 Hub가 연결된 경우
          if (this.$machineHub.hub.state == HubConnectionState.Connected) {
            // 머신 id 중복 제거
            const filter_list = [
              ...new Set(
                this.getMachineList.map(m => {
                  return m.machine_id;
                })
              )
            ];
            // 머신 id 상태 요청
            filter_list.forEach(id => {
              this.$machineHub.hub.invoke("UsingStatus", id);
            });
          }
        }
      });
    },

    async createMachinePlayer(machine_id) {
      const player_id = this.getPlayerId;
      const external_token = this.getExternalToken;

      this.ACTION_CREATE_MACHINE_PLAYER({
        machine_id,
        player_id,
        external_token
      }).then(res => {
        switch (res.status) {
          case 200:
            {
              this.$router.push({
                path: "game",
                query: { id: machine_id }
              });
            }
            break;
          case 401:
            if (this.getPlayerId !== undefined) {
              this.ACTION_AUTH_LOGOUT();
              this.openSignInDialog();
            }
            break;

          case 409:
            /**
             * 409 : The Player is using another machine.
             */
            this.notifyMessage = {
              title: this.$t("notifyMessage.title"),
              text: this.$t("machine.error406"),
              btnText: this.$t("button.confirm")
            };
            this.notifyDialog = true;
            break;
          case 406:
            console.log(res.data.detail);
            /**
             * 406 : There are no seats left.
             */
            this.notifyMessage = {
              title: this.$t("notifyMessage.title"),
              text: this.$t("machine.error409"),
              btnText: this.$t("button.confirm")
            };
            this.notifyDialog = true;
            break;

          default:
            break;
        }
      });
    },
    openSignInDialog() {
      this.signInDialog = true;
    },
    openPlayViewDialog() {
      this.playViewDialog = true;
    },
    closeSignInDialog() {
      this.signInDialog = false;
    },
    closeNotifyDialog() {
      this.notifyDialog = false;
    },

    closePlayViewDialog() {
      this.playViewDialog = false;
      this.viewTargetMachine = {};
    },

    transitionEnd() {
      const swiperIndex = this.$refs.mySwiper.$swiper.realIndex;

      if (this.currentSwiperIndex == swiperIndex) {
        return;
      } else {
        this.currentSwiperIndex = swiperIndex;
      }

      this.isState = 0;
      this.isFavorite = false;

      this.selectMachine = this.machineGameTypeList[this.currentSwiperIndex];

      // 머신 리스트 조회 요청
      this.requestMachineList(this.currentSwiperIndex);
    }
  }
};
</script>
