import axios from "./axios";

/**
 * [XSAPI] Jackpot - jackpot list 조회
 * @returns
 */
export const requestJackpotList = async () => {
  let response = null;

  try {
    response = await axios.get("/jackpots/list");
  } catch (error) {
    throw new Error(error);
  }
  return response;
};
