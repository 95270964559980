<template>
  <div id="main_page" class="xs-container">
    <!-- Banner Section -->
    <BannerSection />

    <!-- Jackpot Section -->
    <JackpotSection @selectJackpot="updateSelectedJackpot" />

    <!-- Game Theme Section -->
    <GameThemeSection
      :defaultTheme="selectedTheme"
      :selectedJackpotId="selectedJackpotId"
      @update:selectedTheme="updateSelectedTheme"
      @update:allowedGameIds="updateAllowedGameIds"
    />

    <!-- Game List Section -->
    <GameGroupForm
      :selectedGameThemeId="selectedTheme"
      :allowedGameIds="allowedGameIds"
    />
  </div>
</template>

<script>
import BannerSection from "@views/components/main/BannerSection.vue";
import JackpotSection from "@views/components/main/JackpotSection.vue";
import GameThemeSection from "@views/components/main/GameThemeSection.vue";
import GameGroupForm from "@views/components/common/GameGroupForm.vue";

export default {
  name: "MainPage",
  components: {
    BannerSection,
    JackpotSection,
    GameThemeSection,
    GameGroupForm
  },
  data() {
    return {
      selectedTheme: "all",
      selectedJackpotId: null,
      allowedGameIds: [] // GameThemeSection에서 전달된 allowedGameIds
    };
  },
  methods: {
    updateSelectedJackpot(jackpotId) {
      this.selectedJackpotId = jackpotId; // 선택한 Jackpot ID를 저장
      this.selectedTheme = "all"; // 새로운 Jackpot 선택 시 Theme 초기화
    },
    updateSelectedTheme(themeId) {
      this.selectedTheme = themeId; // 선택한 Theme ID를 저장
    },
    updateAllowedGameIds(gameIds) {
      this.allowedGameIds = gameIds; // GameThemeSection에서 전달된 gameId 목록을 저장
    }
  }
};
</script>
