import { currencyApi } from "@/api";

const CurrencyNameSpace = "currency";

const CurrencyMutaions = {
  MUTATION_UPDATE_CURRENCY_LIST: "MUTATION_UPDATE_CURRENCY_LIST", // currency 리스트 업데이트
  MUTATION_UPDATE_CURRENCY_DETAIL: "MUTATION_UPDATE_CURRENCY_DETAIL" // 선택한 currency 업데이트
};

const CurrencyActions = {
  ACTION_READ_CURRENCY_LIST: "ACTION_READ_CURRENCY_LIST" // currency 리스트 조회 요청
};

const createInitialState = () => ({
  currency_list: [],
  currency_detail: null
});

const getters = {
  getCurrencyList(state) {
    return state.currency_list;
  },
  getCurrencyDetail(state) {
    return state.currency_detail;
  }
};

const mutations = {
  //currency 리스트 업데이트
  [CurrencyMutaions.MUTATION_UPDATE_CURRENCY_LIST](state, res) {
    state.currency_list = res.list;
  },
  [CurrencyMutaions.MUTATION_UPDATE_CURRENCY_DETAIL](state, res) {
    state.currency_detail = res;
  }
};

const actions = {
  //currency 리스트 조회 요청
  async [CurrencyActions.ACTION_READ_CURRENCY_LIST](context) {
    const { commit } = context;

    let result = await currencyApi.requestCurrencyList().then(res => {
      commit("MUTATION_UPDATE_CURRENCY_LIST", res.data);

      return res;
    });

    return result;
  }
};

export { CurrencyNameSpace, CurrencyMutaions, CurrencyActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
