import Vue from "vue";
import VueRouter from "vue-router";
import BasePage from "@views/BaseLayout.vue";

import MainPage from "@views/pages/main/MainPage.vue"; // 메인 페이지
import GamePage from "@views/pages/game/GamePage.vue"; // 슬롯 머신 페이지
import FullScreenGamePage from "@views/pages/fullgame/FullScreenGamePage.vue"; // full screen 슬롯 머신 페이지
import AllGamesPage from "@views/pages/allgame/AllGamesPage.vue"; // all games 페이지
import SecurityPage from "@views/pages/security/SecurityPage.vue"; // Security 페이지
import TermsPage from "@views/pages/terms/TermsPage.vue"; // Terms of use 페이지
import WebSocketPage from "@views/pages/ws/WebSocketPage.vue"; // 웹 소켓 테스트 페이지
// import NotFound from "@views/NotFound";

import store from "@root/store";
import { MachineNamespace } from "@/store/machine";
import { AuthNamespace } from "@/store/auth";

import VueCookies from "vue-cookies";

Vue.use(VueRouter);

const getCookie = () => {
  let login = false;
  if (VueCookies.isKey("xs")) login = true;
  return login;
};

const tokenValidCheck = async (playerId, externalToken) => {
  let result = await store
    .dispatch(
      `${AuthNamespace}/ACTION_PLAYER_CREDIT`,
      { playerId, externalToken },
      {
        root: true
      }
    )
    .then(res => {
      if (res.status == 200) {
        store.commit(
          `${AuthNamespace}/MUTATION_USER_INFO`,
          {
            playerId: playerId,
            externalToken: externalToken
          },
          {
            root: true
          }
        );
        return true;
      } else {
        return false;
      }
    });

  return result;
};

const machineIdCheck = to => {
  let result = false;
  const machine_id = store.getters["machine/getMachineId"];

  // 머신 아이디가 없는 경우
  if (machine_id == undefined) {
    result = true;
  } else {
    const isExist = Object.keys(to.query).includes("id");

    // 쿼리 스트링이 있는 경우
    if (isExist) {
      if (to.query["id"] !== machine_id) {
        result = true;
        deleteMachinePlayer(machine_id);
      }
    } else {
      result = true;
      deleteMachinePlayer(machine_id);
    }
  }
  return result;
};

const deleteMachinePlayer = machine_id => {
  const player_id = store.getters["auth/getPlayerId"];
  const external_token = store.getters["auth/getExternalToken"];

  store.dispatch(
    `${MachineNamespace}/ACTION_DELETE_MACHINE_PLAYER`,
    { machine_id, player_id, external_token },
    {
      root: true
    }
  );
};

const routes = [
  {
    path: "/",
    component: BasePage,
    children: [
      {
        path: "/",
        name: "main",
        component: MainPage,

        beforeEnter: async (to, from, next) => {
          // 쿠키가 존재하는 경우
          if (getCookie()) {
            // 쿼리가 존재하는 경우
            if ("playerId" in to.query && "externalToken" in to.query) {
              tokenValidCheck(to.query.playerId, to.query.externalToken).then(
                async path => {
                  if (path == true) {
                    next({
                      query: {
                        playerId: to.query.playerId,
                        externalToken: to.query.externalToken
                      }
                    });
                  } else {
                    await store.dispatch(
                      `${AuthNamespace}/ACTION_AUTH_LOGOUT`,
                      {
                        root: true
                      }
                    );
                    next("/");
                  }
                }
              );
            }
            // 쿼리가 존재하지 않는 경우
            else {
              const playerId = store.getters["auth/getPlayerId"];
              const externalToken = store.getters["auth/getExternalToken"];

              // Vuex 데이터가 존재하는 경우
              if (playerId !== undefined && externalToken !== undefined) {
                tokenValidCheck(playerId, externalToken).then(async path => {
                  // 토큰 정보가 유효한 경우
                  if (path == true) {
                    next({
                      path: "/",
                      query: {
                        playerId: playerId,
                        externalToken: externalToken
                      }
                    });
                  }
                  // 토큰 정보가 유효하지 않은 경우
                  else {
                    await store.dispatch(
                      `${AuthNamespace}/ACTION_AUTH_LOGOUT`,
                      {
                        root: true
                      }
                    );

                    next("/");
                  }
                });
              }
              // Vuex 데이터가 존재하지 않는 경우
              else {
                next("/");
              }
            }
          }
          // 쿠키 정보가 없는 경우
          else {
            // 쿠키 없음 - 쿼리가 존재하는 경우
            if ("playerId" in to.query && "externalToken" in to.query) {
              tokenValidCheck(to.query.playerId, to.query.externalToken).then(
                async path => {
                  // 토큰 정보가 유효한 경우
                  if (path == true) {
                    VueCookies.set("xs", true, "");

                    next({
                      path: "/",
                      query: {
                        playerId: to.query.playerId,
                        externalToken: to.query.externalToken
                      }
                    });
                  }
                  // 토큰 정보가 유효하지 않은 경우
                  else {
                    await store.dispatch(
                      `${AuthNamespace}/ACTION_AUTH_LOGOUT`,
                      {
                        root: true
                      }
                    );
                    next("/");
                  }
                }
              );
            }
            // 쿠키 없음 - 쿼리가 존재하지 않는 경우
            else {
              await store.dispatch(`${AuthNamespace}/ACTION_AUTH_LOGOUT`, {
                root: true
              });
              next();
            }
          }
        }
      },

      {
        path: "/game",
        name: "game",
        props: true,
        component: GamePage,
        beforeEnter: (to, from, next) => {
          if (!machineIdCheck(to)) {
            next();
          } else {
            next("/");
          }
        }
      },
      {
        path: "/all-games",
        name: "all-games",
        component: AllGamesPage,
        props: route => ({
          gameId: route.query.gameType
        })
      },
      {
        path: "/security",
        name: "security",
        component: SecurityPage
      },
      {
        path: "/terms",
        name: "terms",
        component: TermsPage
      }
    ]
  },
  {
    path: "/full-game",
    name: "fullscreen-game",
    component: FullScreenGamePage
  },

  {
    path: "/websocket",
    component: WebSocketPage
  },
  {
    path: "*",
    beforeEnter: (to, from, next) => {
      next("/");
    }
  }
];

const router = new VueRouter({
  // page 이동시 무조건 scroll y값 0으로 이동
  // body가 고정, router view의 상위 태그인 main 태그가 스크롤 되고 있기 때문에 페이지 이동 시 main 태그를 scroll top으로 이동
  scrollBehavior() {
    let xsMain = document.getElementById("xs_main");
    xsMain.scrollTo(0, 0);
    return { y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
