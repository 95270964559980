import { gameApi } from "@/api";

const GameNamespace = "game";

const GameMutaions = {
  MUTATION_UPDATE_GAME_LIST: "MUTATION_UPDATE_GAME_LIST" // GAME 리스트 업데이트
};

const GameActions = {
  ACTION_READ_GAME_LIST: "ACTION_READ_GAME_LIST" // GAME 리스트 조회 요청
};

const createInitialState = () => ({
  game_list: []
});

const getters = {
  getGameList(state) {
    return state.game_list;
  }
};

const mutations = {
  //Game 리스트 업데이트
  [GameMutaions.MUTATION_UPDATE_GAME_LIST](state, res) {
    state.game_list = res;
  }
};

const actions = {
  //Game 리스트 조회 요청
  async [GameActions.ACTION_READ_GAME_LIST](context) {
    const { commit } = context;

    let result = await gameApi.requestGameList().then(res => {
      commit("MUTATION_UPDATE_GAME_LIST", res.data);

      return res;
    });

    return result;
  }
};

export { GameNamespace, GameMutaions, GameActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
