import axios from "./axios";

const host_info = process.env.VUE_APP_API_URL;

/**
 * 로그인 요청 API - 사용자
 * @returns
 */
export const requestUserList = async () => {
  let response = null;

  try {
    response = await axios.get("/auth");
  } catch (error) {
    throw new Error(error);
  }
  return response;
};

/**
 * 로그인 요청 API - 사용자
 * @returns
 */
export const requestUserLogin = async (user_id, password) => {
  let response = null;

  try {
    const data = {
      id: user_id,
      pw: password
    };

    response = await axios.post("/auth/login_xapi", data);
  } catch (error) {
    if (error.response.status !== 200) {
      alert("Your login information is incorrect");
    }
  }

  if (response !== null) {
    return response.data;
  } else {
    throw new Error("fail");
  }
};

/**
 * credit 정보 요청 API - 사용자
 * @param {*} player_id
 * @param {*} external_token
 * @returns
 */
export const requestUserCredit = async (player_id) => {
  let response = null;

  try {
    response = await axios.get(`/members/${player_id}/credit`);
  } catch (error) {
    return error.response;
  }
  return response;
};

/**
 * Currency 정보 요청 API - 사용자
 * @param {*} player_id
 * @returns
 */
export const requestUserCurrency = async player_id => {
  let response = null;
  try {
    response = await axios.get(`/currency/${player_id}`);
  } catch (err) {
    return err.response;
  }
  return response;
};

/**
 * Currency 정보 업데이트 요청 API - 사용자
 * @param {*} player_id
 * @param {*} currency
 * @returns
 */
export const updateUserCurrency = async (player_id, currency) => {
  let response = null;

  try {
    const data = {
      id: player_id,
      currency: currency
    };

    response = await axios.put("/currency/update", data);
  } catch (err) {
    return err.response;
  }

  return response;
};
