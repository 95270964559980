import { HubConnectionBuilder } from "@microsoft/signalr";

import { MachineNamespace } from "@/store/machine";

const baseURL = process.env.VUE_APP_API_URL;

export class MachineHub {
  constructor(store) {
    this.hub = null;
    this.store = store;
  }

  init() {
    this.hub = new HubConnectionBuilder()
      .withUrl(`${baseURL}/ws/MachineStatusHub`)
      .build();

    this.hub.onclose(() => {
      this.startHubConnection();
    });

    // 머신 상태 업데이트 수신
    this.hub.on("MachineUsingStatus", (machineId, status) => {
      // 서버를 통해 전달 받은 머신의 상태 업데이트
      const data = {
        machineId: machineId,
        status: status
      };

      this.store.commit(
        `${MachineNamespace}/MUTATION_UPDATE_MACHINE_STATE`,
        data,
        {
          root: true
        }
      );
    });

    // 머신 HUB 연결 완료 업데이트 수신
    this.hub.on("MachineHubStatus", () => {
      // console.log(this.hub.state);

      // 현재 화면에 보여지는 머신 리스트 조회
      let machine_list =
        this.store.getters[`${MachineNamespace}/getMachineList`];

      if (machine_list.length > 0) {
        // 머신 id 중복 제거
        const machine_id_list = new Set(
          machine_list.map(m => {
            return m.machine_id;
          })
        );
        const filter_list = [...machine_id_list];

        // 머신 id 상태 요청
        filter_list.forEach(id => {
          // 머신 리스트내에 id 값이 존재하는 경우
          if (id !== null) {
            this.hub.invoke("UsingStatus", id);
          }
        });
      }
    });

    this.startHubConnection();
  }

  startHubConnection() {
    try {
      this.hub.start();
    } catch (err) {
      setTimeout(this.startHubConnection, 5000);
    }
  }
}
