<template>
  <div id="x-slot">
    <!-- 중복 로그인 알림 -->
    <v-dialog
      content-class="alert duplicate"
      v-if="duplicateDialog"
      v-model="duplicateDialog"
      activator="parent"
      width="auto"
      persistent
    >
      <DuplicateDialog @closeDialog="closeDuplicateDialog" />
    </v-dialog>

    <v-dialog
      content-class="alert duplicate"
      v-if="afkDialog"
      v-model="afkDialog"
      activator="parent"
      width="auto"
      persistent
    >
      <AlertDialog
        @closeDialog="closeAfkDialog"
        :message="afkMessage"
        :isConfirm="false"
      />
    </v-dialog>

    <v-dialog
      content-class="alert terms"
      v-click-outside="closeTermsDialog"
      v-if="termsDialog"
      v-model="termsDialog"
      activator="parent"
      width="auto"
      persistent
    >
      <TermsDialog @closeDialog="closeTermsDialog" />
    </v-dialog>

    <HeaderForm />

    <v-main id="xs_main" class="xs-main">
      <transition name="fade">
        <router-view :key="$route.fullPath" />
      </transition>
    </v-main>
    <FooterForm />
  </div>
</template>
<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.2s ease-in;
}
</style>
<script>
import { io } from "socket.io-client";

import HeaderForm from "@views/components/layout/HeaderForm.vue";
import FooterForm from "@views/components/layout/FooterForm.vue";

import DuplicateDialog from "@views/components/common/dialog/DuplicateDialog.vue";
import AlertDialog from "@views/components/common/dialog/AlertDialog.vue";
import TermsDialog from "@views/components/common/dialog/TermsDialog.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { AuthNamespace, AuthActions, AuthMutations } from "@/store/auth";
import { MachineNamespace, MachineMutations } from "@/store/machine";

export default {
  components: {
    HeaderForm,
    FooterForm,
    DuplicateDialog,
    AlertDialog,
    TermsDialog
  },
  data() {
    return {
      duplicateDialog: false,
      afkDialog: false,
      afkMessage: {},
      termsDialog: false
    };
  },

  watch: {
    // 중복 로그인 값 감지
    getDuplicateLogin() {
      if (this.getDuplicateLogin) {
        this.duplicateDialog = true;
      }
    },

    // AFK 데이터 감지
    getAfkData() {
      if (this.getAfkData != null) {
        window.location.replace("/");
      }
    },

    termsDialog() {}
  },

  computed: {
    ...mapGetters(AuthNamespace, [
      "getPlayerId",
      "getDuplicateLogin",
      "getAfkData",
      "getLoginCheck"
    ])
  },

  mounted() {
    // AFK kickout인 경우
    if (this.getAfkData != null) {
      this.afkMessage = {
        title: "",
        text: `You have been auto-logout \nfrom the machine due to \ninactivity of ( ${this.getAfkData} ) seconds.\nAll credits in machine have been\nreturned to wallet.`,
        btnText: "OK"
      };

      this.afkDialog = true;
    }

    // term 모달
    this.openTermsDialog();

    // 100vh 적용(앱바 및 도구바 제외 100vh)
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);

    this.observerTarget();
    const mediaQuery = window.matchMedia("screen and (max-width: 768px)");
    if (mediaQuery.matches) {
      let header = document.querySelector(".slot-header");
      let headerHeight = header.offsetHeight;
      window.onscroll = function () {
        let windowTop = window.scrollY;

        if (windowTop >= headerHeight) {
          header.classList.add("fixed");
        } else {
          header.classList.remove("fixed");
        }
      };
    }

    const socket = io(process.env.VUE_APP_ROOT_API, {
      withCredentials: true
    });

    socket.on("credit:updated", async () => {
      if (this.getLoginCheck) {
        this.requestCreditInfo();
      }
    });
  },
  methods: {
    ...mapActions(AuthNamespace, {
      ACTION_AUTH_LOGIN: AuthActions.ACTION_AUTH_LOGIN,
      ACTION_PLAYER_CREDIT: AuthActions.ACTION_PLAYER_CREDIT,
      ACTION_AUTH_LOGOUT: AuthActions.ACTION_AUTH_LOGOUT
    }),

    ...mapMutations(AuthNamespace, {
      MUTATION_UPDATE_AFK_INFO: AuthMutations.MUTATION_UPDATE_AFK_INFO
    }),

    ...mapMutations(MachineNamespace, {
      MUTATION_USER_MACHINE: MachineMutations.MUTATION_USER_MACHINE
    }),

    /**
     * 브라우저의 도구 바를 제외하기 위해 innerheight값으로만 100vh 구현
     * vh -> innerHeight를 100분의 1한 값
     */
    setScreenSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },

    // window resize 될 때마다 html font-size 변경 (반응형 대응)
    observerTarget() {
      const target = document.querySelector("html");
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (entry.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize[0];

            if (matchMedia("screen and (min-width: 768px)").matches) {
              target.style.fontSize = `${Math.min(
                16, // 최대값 제한
                Math.max(8, contentBoxSize.inlineSize / 120) // 최소값 제한
              )}px`;
            } else {
              // 768px 미만에서 사용할 JavaScript
              target.style.fontSize = `${Math.floor(
                contentBoxSize.inlineSize / 60
              )}px`;
            }
          }
        });
      });

      observer.observe(target);
    },

    openTermsDialog() {
      if (!this.getLoginCheck) {
        if (self.window.name.indexOf("justAwordToNowisYourWindow") != 0) {
          self.window.name = "justAwordToNowisYourWindow";
          this.termsDialog = true;
        }
      }
    },

    // 중복 로그인 알림 다이얼로그 닫기 이벤트
    closeDuplicateDialog() {
      this.duplicateDialog = false;

      this.ACTION_AUTH_LOGOUT(); // 사용자 로그아웃
      this.MUTATION_USER_MACHINE(undefined);
      window.location.replace("/");
    },

    // AFK 알림 다이얼로그 닫기 이벤트
    closeAfkDialog(param) {
      this.MUTATION_UPDATE_AFK_INFO(null);
      this.afkDialog = param;
    },

    closeTermsDialog(param) {
      this.termsDialog = param;
    },

    // 크레딧 정보 요청
    async requestCreditInfo() {
      await this.ACTION_PLAYER_CREDIT({
        playerId: this.getPlayerId,
        externalToken: this.getExternalToken
      });
    }
  }
};
</script>
