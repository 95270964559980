<template>
  <div class="vertical-modal-inner">
    <button
      type="button"
      class="btn-close"
      @click="closeDialog(false)"
    ></button>
    <div class="vertical-modal transection">
      <div class="vertical-inner">
        <div class="vertical_title-box">
          <div class="title-box">
            <h2>
              Transection <br />
              History
            </h2>
          </div>
          <div class="vertical-tab">
            <v-tabs v-model="tab" background-color="transparent" vertical>
              <v-tab
                v-for="item in transectionItems"
                :key="item"
                :disabled="item === 'Withdraw'"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
        </div>
        <div class="vertical-tab-items">
          <v-tabs-items v-model="tab">
            <v-tab-item :key="transectionItems.Deposit">
              <div class="tab-section d-flex">
                <v-tabs v-model="tabs" background-color="transparent">
                  <v-tab
                    v-for="item in periodTabs"
                    :key="item"
                    :disabled="
                      item === '3days' || item === '7days' || item === '30days'
                    "
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <v-btn class="choose-tab" @click="filterDialog = true"></v-btn>
              </div>

              <div class="tabs-items">
                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="periodTabs.Today">
                    <section class="tab_item-section">
                      <div class="item-title">
                        <h2>Total 30days:</h2>
                        <p class="total-amount"><span>23.05</span>USD</p>
                      </div>
                      <div class="border-box separate">
                        <div class="separate-top">
                          <div class="top-box">
                            <p class="result-amount font-white">15.34USD</p>
                            <p class="result-status">Success</p>
                          </div>
                          <div class="top-box">
                            <div class="copy-box">
                              <p class="copy-text font-white">
                                231122173850927MZJ6
                              </p>
                              <v-btn class="copy-btn"></v-btn>
                            </div>
                            <p class="result-timestamp">11/22 18/22</p>
                          </div>
                        </div>
                        <hr class="separate-hr" />
                        <div class="separate-bottom">
                          <section class="result-section">
                            <div class="result-box">
                              <ul class="result-lists">
                                <li class="result-list">
                                  <div class="result-total">
                                    <p class="result-title">Channel</p>
                                    <p class="result-text">S88-pay</p>
                                  </div>
                                </li>
                                <li class="result-list">
                                  <div class="result-total">
                                    <p class="result-title">Exchange rate</p>
                                    <p class="result-text">1Usd ~ 1303.15KRW</p>
                                  </div>
                                </li>
                                <li class="result-list">
                                  <div class="result-total">
                                    <p class="result-title">Deposit amount</p>
                                    <p class="result-text">20,000.00 KRW</p>
                                  </div>
                                </li>
                                <li class="result-list">
                                  <div class="result-total">
                                    <p class="result-title">Amount Received</p>
                                    <p class="result-text">15.34 USD</p>
                                  </div>
                                </li>
                                <li class="result-list">
                                  <div class="result-total">
                                    <p class="result-title">Deposit Time</p>
                                    <p class="result-text">
                                      2023-11-22 18:42:36
                                    </p>
                                  </div>
                                </li>
                                <li class="result-list">
                                  <div class="result-total">
                                    <p class="result-title">Order</p>
                                    <div class="copy-box">
                                      <p class="copy-text result-text">
                                        231122173850927MZJ6
                                      </p>
                                      <v-btn class="copy-btn"></v-btn>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </section>
                        </div>
                        <div class="box-btn">
                          <div class="deposit-again">
                            <v-btn class="deposit-btn">Deposit again</v-btn>
                          </div>
                          <p class="link-text">
                            Any questions?
                            <a href="#">Contact customer service</a>
                          </p>
                        </div>
                      </div>
                    </section>
                    <section class="tab_item-section">
                      <v-expansion-panels class="border-box">
                        <v-expansion-panel class="expansion-panel">
                          <v-expansion-panel-header>
                            <div class="accord-section">
                              <p class="accord-result">0.00</p>
                              <p class="accord_result-status fail">Fail</p>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="copy-box">
                              <p class="copy-text">231122173850927MZJ6</p>
                              <v-btn class="copy-btn"></v-btn>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </section>
                    <section class="tab_item-section">
                      <v-expansion-panels class="border-box">
                        <v-expansion-panel class="expansion-panel">
                          <v-expansion-panel-header>
                            <div class="accord-section">
                              <p class="accord-result">0.00</p>
                              <p class="accord_result-status">Success</p>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="copy-box">
                              <p class="copy-text">231122173850927MZJ6</p>
                              <v-btn class="copy-btn"></v-btn>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </section>
                    <section class="tab_item-section">
                      <v-expansion-panels class="border-box">
                        <v-expansion-panel class="expansion-panel">
                          <v-expansion-panel-header>
                            <div class="accord-section">
                              <p class="accord-result">0.00</p>
                              <p class="accord_result-status">Success</p>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="copy-box">
                              <p class="copy-text">231122173850927MZJ6</p>
                              <v-btn class="copy-btn"></v-btn>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </section>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>
            <v-tab-item :key="transectionItems.Withdraw"> </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
    <v-dialog
      content-class="division"
      v-model="filterDialog"
      v-if="filterDialog"
      activator="parent"
      width="auto"
    >
      <FilterDialog @closeDialog="closeFilterDialog" />
    </v-dialog>
  </div>
</template>
<script>
import FilterDialog from "./FilterDialog.vue";
export default {
  components: {
    FilterDialog
  },
  data() {
    return {
      tab: null,
      tabs: null,
      transectionItems: ["Deposit", "Withdraw"],
      periodTabs: ["Today", "3days", "7days", "30days"],
      filterDialog: false
    };
  },
  methods: {
    // 모달 닫기 이벤트 emit
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    },
    closeFilterDialog() {
      this.filterDialog = false;
    }
  }
};
</script>
