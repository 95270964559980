<template>
  <div class="alert-common modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        <v-icon class="notify-icon"></v-icon>
        <h2>{{ message.title }}</h2>
      </h5>
    </div>
    <div class="message-area modal-body">
      <div class="alert-message">
        <p class="terms-message">{{ message.text }}</p>
        <ol class="terms-notice">
          <li>You are 21 years or older.</li>
          <li>
            You agree to [X GAMES]'s
            <router-link to="/terms" @click.native="closeDialog(false)"
              >Terms of use.</router-link
            >
          </li>
        </ol>
        <div class="terms-image">
          <div class="image-box">
            <img src="@assets/img/dialog/terms/terms-img01.png" alt="pagcor" />
          </div>
          <div class="image-box">
            <img src="@assets/img/dialog/terms/terms-img02.png" alt="pagcor" />
          </div>
        </div>
      </div>
    </div>
    <div class="message-button">
      <v-btn class="accent" @click="closeDialog(false)">{{
        message.btnText
      }}</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({
        title: "Notice",
        text: "By entering this website, you acknowledge and confirm:",
        btnText: "I agree!"
      })
    }
  },

  methods: {
    // 모달 닫기 이벤트 emit
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    }
  }
};
</script>
