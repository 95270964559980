<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ $t("profileDialog.currencyDialog.currencyDesc") }}
      </h5>
    </div>
    <div class="modal-body">
      <div class="currency-lists">
        <v-radio-group
          v-for="(item, index) in getCurrencyList"
          :key="index"
          class="currency-list"
          v-model="checkCurrency"
        >
          <v-radio :value="item.Code" hide-details>
            <template v-slot:label>
              <div class="currency-box">
                <div class="currency-inner">
                  <div class="currency-img">
                    <img
                      :src="
                        require(`@assets/img/dialog/currency/${item.Code}.png`)
                      "
                    />
                  </div>
                  <div class="currency-code">
                    <p>{{ item.Symbol }} ({{ item.Code }})</p>
                  </div>
                </div>
                <!-- <img :src="require(`@assets/img/login/${item.img}`)" alt="" /> -->
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="confirm-button">
      <v-btn @click="closeDialog()">{{ $t("button.cancel") }}</v-btn>
      <v-btn class="accent" @click="changeCurrency()">{{
        $t("button.confirm")
      }}</v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { AuthNamespace, AuthActions } from "@/store/auth";
import { CurrencyNameSpace, CurrencyMutaions } from "@/store/currency";
export default {
  data() {
    return {
      checkCurrency: ""
    };
  },
  computed: {
    ...mapGetters(AuthNamespace, ["getPlayerId"]),
    ...mapGetters(CurrencyNameSpace, ["getCurrencyList"])
  },
  methods: {
    ...mapActions(AuthNamespace, {
      ACTION_UPDATE_PLAYER_CURRENCY: AuthActions.ACTION_UPDATE_PLAYER_CURRENCY
    }),
    ...mapMutations(CurrencyNameSpace, {
      MUTATION_UPDATE_CURRENCY_DETAIL:
        CurrencyMutaions.MUTATION_UPDATE_CURRENCY_DETAIL
    }),
    // 모달 닫기 이벤트 emit
    closeDialog() {
      this.$emit("closeDialog");
    },
    // 통화 변경 이벤트
    async changeCurrency() {
      let selectCurrency = this.getCurrencyList.find(
        cl => cl.Code === this.checkCurrency
      );

      if (selectCurrency) {
        // 유저 커런시 정보 업데이트
        await this.ACTION_UPDATE_PLAYER_CURRENCY({
          playerId: this.getPlayerId,
          currency: selectCurrency.Code
        });

        this.MUTATION_UPDATE_CURRENCY_DETAIL(selectCurrency);

        this.closeDialog();
      }
    }
  }
};
</script>
