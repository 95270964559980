import { jackpotApi } from "@/api";

const JackpotNamespace = "jackpot";

const JackpotMutaions = {
  MUTATION_UPDATE_JACKPOT_LIST: "MUTATION_UPDATE_JACKPOT_LIST" // JACKPOT 리스트 업데이트
};

const JackpotActions = {
  ACTION_READ_JACKPOT_LIST: "ACTION_READ_JACKPOT_LIST" // JACKPOT 리스트 조회 요청
};

const createInitialState = () => ({
  jackpot_list: []
});

const getters = {
  getJackpotList(state) {
    return state.jackpot_list;
  }
};

const mutations = {
  //JACKPOT 리스트 업데이트
  [JackpotMutaions.MUTATION_UPDATE_JACKPOT_LIST](state, res) {
    state.jackpot_list = res.list;
  }
};

const actions = {
  //JACKPOT 리스트 조회 요청
  async [JackpotActions.ACTION_READ_JACKPOT_LIST](context) {
    const { commit } = context;

    let result = await jackpotApi.requestJackpotList().then(res => {
      commit("MUTATION_UPDATE_JACKPOT_LIST", res.data);

      return res;
    });

    return result;
  }
};

export { JackpotNamespace, JackpotMutaions, JackpotActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
